// Clearing Main Firebase Message
console.clear();
console.info("%cYou Are Using Firework.js v1.0.9 In Production Mode, Your Code Is Optimized For Size And Speed, Built By BroCoders©", "color:#fff; font-size: 14px; background:#FFA611;padding:8px;text-align:center;border-radius:10px");

const $el = element => document.querySelector(`${element}`);

// Config Setup
const config = configs => {
    firebase.initializeApp(configs);
};

// Authentication Operations
const auth = {
    user: () => firebase.auth().currentUser
    ,
    signup: (email, password) => firebase.auth().createUserWithEmailAndPassword(email, password)
    ,
    login: (email, password) => firebase.auth().signInWithEmailAndPassword(email, password)
    ,
    logout: () => firebase.auth().signOut()
    ,
    onAuth: func => firebase.auth().onAuthStateChanged(func)
    ,
    onLogin: func => firebase.auth().onAuthStateChanged(() => {
        if(auth.user() != null){
            (func)();
        }
    })
    ,
    onNoUser: func => firebase.auth().onAuthStateChanged(() => {
        if(auth.user() == null){
            (func)();
        }
    })
    ,
    onDisconnect: (ref, data) => firebase.database().ref(ref).onDisconnect().update(data)
    ,
    updateProfile: data => auth.user().updateProfile(data)
    ,
    updateEmail: email => auth.user().updateEmail(email)
    ,
    updatePassword: password => auth.user().updatePassword(password)
    ,
    verifyEmail: () => auth.user().sendEmailVerification()
    ,
    emailVerified: () => auth.user().emailVerified
    ,
    resetPassword: email => firebase.auth().sendPasswordResetEmail(email)
    ,
    delete: () => auth.user().delete()
    ,
    sendPhoneCode: phoneNumber => {
        var container = document.createElement("div");
        container.id = "UNIQUE_RECAPTCHA";
        document.body.appendChild(container);
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(`UNIQUE_RECAPTCHA`, {'size': 'invisible'});
        var reCAPTCHA = window.recaptchaVerifier;
        
        return firebase.auth().signInWithPhoneNumber(phoneNumber, reCAPTCHA).then(confirmationResult => {
            window.confirmationResult = confirmationResult; 
            var element = document.getElementById("UNIQUE_RECAPTCHA");
            element.parentNode.removeChild(element);
        });
    }
    ,
    loginWithPhone: code => confirmationResult.confirm(code)
    ,
    loginWithGoogle: () => firebase.auth().signInWithPopup(new firebase.auth.GoogleAuthProvider())
    ,
    loginWithFacebook: () => firebase.auth().signInWithPopup(new firebase.auth.FacebookAuthProvider())
    ,
    loginWithTwitter: () => firebase.auth().signInWithPopup(new firebase.auth.TwitterAuthProvider())
    ,
    loginWithGithub: () => firebase.auth().signInWithPopup(new firebase.auth.GithubAuthProvider())
    ,
    loginWithMicrosoft: () => firebase.auth().signInWithPopup(new firebase.auth.OAuthProvider('microsoft.com'))
    ,
    loginWithYahoo: () => firebase.auth().signInWithPopup(new firebase.auth.OAuthProvider('yahoo.com'))
    ,
    linkToEmail: (email, password) => auth.user().linkAndRetrieveDataWithCredential(firebase.auth.EmailAuthProvider.credential(email, password))
    ,
    linkToGoogle: () => auth.user().linkWithPopup(new firebase.auth.GoogleAuthProvider())
    ,
    linkToFacebook: () => auth.user().linkWithPopup(new firebase.auth.FacebookAuthProvider())
    ,
    linkToTwitter: () => auth.user().linkWithPopup(new firebase.auth.TwitterAuthProvider())
    ,
    linkToGithub: () => auth.user().linkWithPopup(new firebase.auth.GithubAuthProvider())
    ,
    linkToMicrosoft: () => auth.user().linkWithPopup(new firebase.auth.OAuthProvider('microsoft.com'))
    ,
    linkToYahoo: () => auth.user().linkWithPopup(new firebase.auth.OAuthProvider('yahoo.com'))
    ,
    unlink: provider => auth.user().unlink(provider)
    ,
    setLocalAuth: () => firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    ,
    setSessionAuth: () => firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
    ,
    setNoneAuth: () => firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE)
}


const sorting = function (sorts) {
    return function(a, b){
        for (var i = 0; i < sorts.length; i++){
        if(sorts[i].startsWith("-")){
            if (a[sorts[i].substr(1)] < b[sorts[i].substr(1)]) return -1;
            if (a[sorts[i].substr(1)] > b[sorts[i].substr(1)]) return 1;
        } else {
            if (a[sorts[i]] > b[sorts[i]]) return -1;
            if (a[sorts[i]] < b[sorts[i]]) return 1;
        }
        }
    }
}

const sort = function(data, args){
    data.sort(sorting(args));
}


// Realtime Database Operations
const db = {
    get: (path, options = { order: false, filter: false, sort: false }) => {

        if( options.order && !options.filter ){
            if( options.order.type == "key" ){
                var ref = firebase.database().ref(path).orderByKey();
            } else if( options.order.type == "value" ){
                var ref = firebase.database().ref(path).orderByValue(options.order.value);
            } else if( options.order.type == "child" ){
                var ref = firebase.database().ref(path).orderByChild(options.order.value);
            }
        }

        else if( options.filter && !options.order ){
            if( options.filter.type == "first" ){
                var ref = firebase.database().ref(path).limitToFirst(options.filter.value);
            } else if( options.filter.type == "last" ){
                var ref = firebase.database().ref(path).limitToLast(options.filter.value);
            }
        }
        
        else if ( options.order && options.filter ) {
            if( options.order.type == "key" && options.filter.type == "first" ){
                var ref = firebase.database().ref(path).orderByKey().limitToFirst(options.filter.value);
            } else if ( options.order.type == "key" && options.filter.type == "last" ){
                var ref = firebase.database().ref(path).orderByKey().limitToLast(options.filter.value);
            } else if ( options.order.type == "key" && options.filter.type == "start" ){
                var ref = firebase.database().ref(path).orderByKey().startAt(options.filter.value);
            } else if ( options.order.type == "key" && options.filter.type == "end" ){
                var ref = firebase.database().ref(path).orderByKey().endAt(options.filter.value);
            } else if ( options.order.type == "key" && options.filter.type == "equal" ){
                var ref = firebase.database().ref(path).orderByKey().equalTo(options.filter.value);
            }
            else if( options.order.type == "value" && options.filter.type == "first" ){
                var ref = firebase.database().ref(path).orderByValue(options.order.value).limitToFirst(options.filter.value);
            } else if ( options.order.type == "value" && options.filter.type == "last" ){
                var ref = firebase.database().ref(path).orderByValue(options.order.value).limitToLast(options.filter.value);
            } else if ( options.order.type == "value" && options.filter.type == "start" ){
                var ref = firebase.database().ref(path).orderByValue(options.order.value).startAt(options.filter.value);
            } else if ( options.order.type == "value" && options.filter.type == "end" ){
                var ref = firebase.database().ref(path).orderByValue(options.order.value).endAt(options.filter.value);
            } else if ( options.order.type == "value" && options.filter.type == "equal" ){
                var ref = firebase.database().ref(path).orderByValue(options.order.value).equalTo(options.filter.value);
            }
            else if( options.order.type == "child" && options.filter.type == "first" ){
                var ref = firebase.database().ref(path).orderByChild(options.order.value).limitToFirst(options.filter.value);
            } else if ( options.order.type == "child" && options.filter.type == "last" ){
                var ref = firebase.database().ref(path).orderByChild(options.order.value).limitToLast(options.filter.value);
            } else if ( options.order.type == "child" && options.filter.type == "start" ){
                var ref = firebase.database().ref(path).orderByChild(options.order.value).startAt(options.filter.value);
            } else if ( options.order.type == "child" && options.filter.type == "end" ){
                var ref = firebase.database().ref(path).orderByChild(options.order.value).endAt(options.filter.value);
            } else if ( options.order.type == "child" && options.filter.type == "equal" ){
                var ref = firebase.database().ref(path).orderByChild(options.order.value).equalTo(options.filter.value);
            }
        }

        else {
            var ref = firebase.database().ref(path);
        }

        if(options.sort){
            return ref.once("value").then(snapshot => {
                if(snapshot.exists()){
                    var data = Object.values(snapshot.val());
                    sort(data, options.sort);
                    return {data: data, key: snapshot.key};
                }
            });
        } else {
            return ref.once("value").then(snapshot => {
                if(snapshot.exists()){
                    var data = Object.values(snapshot.val());
                    return {data, key: snapshot.key};
                }
            });
        }

    },
    getting: (path, response, options = { order: false, filter: false, sort: false }) => {

        if( options.order && !options.filter ){
            if( options.order.type == "key" ){
                var ref = firebase.database().ref(path).orderByKey();
            } else if( options.order.type == "value" ){
                var ref = firebase.database().ref(path).orderByValue(options.order.value);
            } else if( options.order.type == "child" ){
                var ref = firebase.database().ref(path).orderByChild(options.order.value);
            }
        }

        else if( options.filter && !options.order ){
            if( options.filter.type == "first" ){
                var ref = firebase.database().ref(path).limitToFirst(options.filter.value);
            } else if( options.filter.type == "last" ){
                var ref = firebase.database().ref(path).limitToLast(options.filter.value);
            }
        }
        
        else if ( options.order && options.filter ) {
            if( options.order.type == "key" && options.filter.type == "first" ){
                var ref = firebase.database().ref(path).orderByKey().limitToFirst(options.filter.value);
            } else if ( options.order.type == "key" && options.filter.type == "last" ){
                var ref = firebase.database().ref(path).orderByKey().limitToLast(options.filter.value);
            } else if ( options.order.type == "key" && options.filter.type == "start" ){
                var ref = firebase.database().ref(path).orderByKey().startAt(options.filter.value);
            } else if ( options.order.type == "key" && options.filter.type == "end" ){
                var ref = firebase.database().ref(path).orderByKey().endAt(options.filter.value);
            } else if ( options.order.type == "key" && options.filter.type == "equal" ){
                var ref = firebase.database().ref(path).orderByKey().equalTo(options.filter.value);
            } 
            else if( options.order.type == "value" && options.filter.type == "first" ){
                var ref = firebase.database().ref(path).orderByValue(options.order.value).limitToFirst(options.filter.value);
            } else if ( options.order.type == "value" && options.filter.type == "last" ){
                var ref = firebase.database().ref(path).orderByValue(options.order.value).limitToLast(options.filter.value);
            } else if ( options.order.type == "value" && options.filter.type == "start" ){
                var ref = firebase.database().ref(path).orderByValue(options.order.value).startAt(options.filter.value);
            } else if ( options.order.type == "value" && options.filter.type == "end" ){
                var ref = firebase.database().ref(path).orderByValue(options.order.value).endAt(options.filter.value);
            } else if ( options.order.type == "value" && options.filter.type == "equal" ){
                var ref = firebase.database().ref(path).orderByValue(options.order.value).equalTo(options.filter.value);
            } 
            else if( options.order.type == "child" && options.filter.type == "first" ){
                var ref = firebase.database().ref(path).orderByChild(options.order.value).limitToFirst(options.filter.value);
            } else if ( options.order.type == "child" && options.filter.type == "last" ){
                var ref = firebase.database().ref(path).orderByChild(options.order.value).limitToLast(options.filter.value);
            } else if ( options.order.type == "child" && options.filter.type == "start" ){
                var ref = firebase.database().ref(path).orderByChild(options.order.value).startAt(options.filter.value);
            } else if ( options.order.type == "child" && options.filter.type == "end" ){
                var ref = firebase.database().ref(path).orderByChild(options.order.value).endAt(options.filter.value);
            } else if ( options.order.type == "child" && options.filter.type == "equal" ){
                var ref = firebase.database().ref(path).orderByChild(options.order.value).equalTo(options.filter.value);
            }
        } 

        else {
            var ref = firebase.database().ref(path);
        }


        if(options.sort){
            ref.on("value", snapshot => {
                if(snapshot.exists()){
                    var data = Object.values(snapshot.val());
                    sort(data, options.sort);
                    res = {data, key: snapshot.key};
                    return response(res);
                }
            });
        } else {
            ref.on("value", snapshot => {
                if(snapshot.exists()){
                    var data = Object.values(snapshot.val());
                    res = {data, key: snapshot.key};
                    return response(res);
                }
            });
        }

    },
    onAdd: (ref, func) => firebase.database().ref(ref).on('child_added', func)
    ,
    onUpdate: (ref, func) => firebase.database().ref(ref).on('child_changed', func)
    ,
    onDelete: (ref, func) => firebase.database().ref(ref).on('child_removed', func)
    ,
    add: (path, data) => firebase.database().ref(path).set(data)
    ,
    push: (path, data) => new Promise(resolve => {
        resolve(firebase.database().ref(path).push(data).key);
    })
    ,
    update: (path, data) => firebase.database().ref(path).update(data)
    ,
    delete: path => firebase.database().ref(path).remove()
};


// Storage Operations
const storage = {
    upload: (inputFileSelector, ref, metadata = false) => {
        var file = $el(inputFileSelector).files[0];
        if(!metadata){
            metadata = { contentType: file.type };
        }
        return firebase.storage().ref(`${ref}/${file.name}`).put(file, metadata).then(() => {
            return firebase.storage().ref(`${ref}/${file.name}`).getDownloadURL();
        });
    },
    download: path => {
        return firebase.storage().ref(path).getDownloadURL().then(url => {
            if(url){
                let a = document.createElement('a');
                a.href = url;
                a.target = "_blank";
                a.download = url.split('/').pop();
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
        });
    },
    getRef: url => {
        return new Promise(resolve => {
            resolve(firebase.storage().refFromURL(url).fullPath);
        });
    },
    getURL: path => firebase.storage().ref(path).getDownloadURL()
    ,
    getMetadata: path => firebase.storage().ref(path).getMetadata()
    ,
    updateMetadata: (path, metadata) => firebase.storage().ref(path).updateMetadata(metadata)
    ,
    delete: path => firebase.storage().ref(path).delete()
};


// Admin Auth Opertaions { * Deploying Firebase Functions Is Required * }
const Admin = {
    makeMaster: email => {
        const makeMasterAdminFunction = firebase.functions().httpsCallable('makeMaster');
        return makeMasterAdminFunction(email);
    },
    makeAdmin: email => {
        const makeAdminFunction = firebase.functions().httpsCallable('makeAdmin');
        return makeAdminFunction(email);
    },
    removeAdmin: email => {
        const removeAdminFunction = firebase.functions().httpsCallable('removeAdmin');
        return removeAdminFunction(email);
    },
    createUser: (email, password) => {
        const createUserFunction = firebase.functions().httpsCallable('createUser');
        return createUserFunction({ email, password });
    },
    updateUser: (email, updates) => { 
        const updateUserFunction = firebase.functions().httpsCallable('updateUser');
        return updateUserFunction({ email, updates });
    },
    getUser: email => { 
        const getUserFunction = firebase.functions().httpsCallable('getUser');
        return getUserFunction(email);
    },
    disableUser: email => {
        const disableUserFunction = firebase.functions().httpsCallable('updateUser');
        return disableUserFunction({
            email,
            updates: {
                disabled: true
            }
         });
    },
    enableUser: email => {
        const disableUserFunction = firebase.functions().httpsCallable('updateUser');
        return disableUserFunction({
            email,
            updates: {
                disabled: false
            }
         });
    },
    deleteUser: email => {
        const deleteUserFunction = firebase.functions().httpsCallable('deleteUser');
        return deleteUserFunction(email);
    },
    listAllUsers: () => {
        const listUserFunction = firebase.functions().httpsCallable('listAllUsers');
        return listUserFunction();
    }
}


// Firebase Messaging Opertaions { * firebase-messaging-sw.js AND manifest.json Is Required * }
const notifications = {
    subscribe: () => {
        firebase.messaging().requestPermission().then(() => {
            firebase.messaging().getToken().then(token => {
                const options = { 
                    order: { type: "child", value: "token" }, 
                    filter: { type: "equal", value: token }
                };
                db.get(`tokens`, options).then(res => {
                    if(!res){
                        db.push(`tokens`, {
                            token,
                            user: auth.user().uid
                        });
                    }
                });
            });
        });
    },
    unsubscribe: () => {
        firebase.messaging().getToken().then(token => {
            firebase.messaging().deleteToken(token).then(() => {
                const options = { 
                    order: { type: "child", value: "token" }, 
                    filter: { type: "equal", value: token }
                };
                db.get('tokens',  options).then(res => {
                    const key = Object.keys(res.data)[0];
                    db.delete(`tokens/${key}`);
                })
            });
        });
    },
    send: (title, body, icon, redirectURL = "index.html") => {
        if ( !title || !body || !icon ) return;
        db.push("notifications", {
            title: title,
            body: body,
            icon: icon,
            url: redirectURL
        });
    },
    refreshToken: () => {
        firebase.messaging().onTokenRefresh(() => {
            return firebase.messaging().getToken().then(token => {
                db.push(`tokens`, {
                    token: token,
                    user: auth.user().uid
                });
            });
        })
    }
}
